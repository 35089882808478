(function(window) {

	var GamTheme = function() {
		
		var enableScrolloverAnimations = function() {
			
			function delay($el, delay) {
				setTimeout(function () {
					$el.addClass('animate');
				}, delay);
			}

			if ($('.js-animate').length > 0) {
				var threshold = 100;
				var scroll;
				var $targets;
				var index;
				$(window).scroll(function(){
					scroll = $(this).scrollTop() + $(this).height();
					$('.js-animate:not(.actived)').each(function(e) {
						if (scroll > $(this).offset().top + threshold) {
							if ($(this).hasClass('js-animate-target')) {
								$targets = $(this);
							} else {
								$targets = $(this).find('.js-animate-target');
							}
							$(this).addClass('actived');
							index = 0;
							$targets.each(function() {
								delay($(this), index*150);
								index++;
							});
						}
					});
				});
			}
		};
		
		var enableMobileTimeline = function() {
			
			var redrawControls = function(idx) {
				$('.timeline-mobile-control.hidden').removeClass('hidden');
				if (idx === 0) {
					$('.timeline-mobile-control.-prev').addClass('hidden');
				} else if (idx === $('.timeline-block').length-1) {
					$('.timeline-mobile-control.-next').addClass('hidden');
				}
			};
			
			var activate = function(idx) {
				$('.timeline-block.active').removeClass('active');
				$('.timeline-dot.active').removeClass('active');
				
				$('.timeline-block').eq(idx).addClass('active');
				$('.timeline-dot').eq(idx).addClass('active');
				
				redrawControls(idx);
			};
			
			$('.timeline-mobile-control').on('click', function() {
				if ($(this).hasClass('-prev')) {
					if ($('.timeline-block.active').prev().length > 0) {
						activate($('.timeline-block.active').prev().index());
					}
				} else if ($(this).hasClass('-next')) {
					if ($('.timeline-block.active').next().length > 0) {
						activate($('.timeline-block.active').next().index());
					}
				}
			});
			
			$('.timeline-dot').on('click', function() {
				activate($(this).index());
			});
			
			activate(0);
		};
		
		var enableMap = function() {
			var cords = {
				lat: 43.787694,
				lng: -79.629775
			};
			var $map = $('.contact-form-map');
			
			if ($map.length > 0) {
				var map = new google.maps.Map($map[0], {
					zoom: 15,
					center: cords,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				});
				var marker = new google.maps.Marker({
					map: map,
					position: cords,
					title: 'GAM Creative Marketing'
				});
			}
		};
		
		var enableDynamicFormElements = function() {
			$('.form-group.-error .form-control').on('focus', function() {
				$(this).parent().parent().removeClass('-error');
			});
			$('.form-group .form-control').on('focus', function() {
				$(this).parent().parent().addClass('active');
			});
			$('.form-group .form-control').on('blur', function() {
				if ($(this).val() === '') {
					$(this).parent().parent().removeClass('active');
				}
			});
			
			$('.form-group .form-control').each(function() {
				if ($(this).val() !== '') {
					$(this).parent().parent().addClass('active');
				}
			});
		};

		this.enableMap = enableMap;
		this.enableMobileTimeline = enableMobileTimeline;
		this.enableScrolloverAnimations = enableScrolloverAnimations;
		this.enableDynamicFormElements = enableDynamicFormElements;

	};

	/* -------------------------------------------------------------------------- */

	$(document).ready(function() {
		gamTheme = new GamTheme();
		gamTheme.enableMap();
		gamTheme.enableMobileTimeline();
		gamTheme.enableScrolloverAnimations();
		gamTheme.enableDynamicFormElements();
		
		window.gamTheme = gamTheme;
		
		$(window).trigger('resize');
		$(window).trigger('scroll');
	});

})(window);